body {
  background-color: #f0f2f5 !important;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper{
  min-height: 100vh;
  background-color: rgb(245, 245, 245);
}

.login-form-wrapper{
  /* height: 100%; */
}

.login-cover{

}

.login-form-container {
  min-height: 100vh;
  background-color: #fff;
  padding:40px 60px;
  box-shadow: 1px 1px 5px 0px rgba(138,138,138,1);
}
.login-form {
  /* padding:10px; */
  margin:40px 0;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

@media (max-width:700px){
  .wrapper{
    padding:50px 10px;
  }

  .login-form-container {
    padding:50px 20px;
  }
}

p.text-about{
  font-size: 16px;
}
.text-head{
  font-weight: bold;
}
.text-poin{
  font-size: 16px;
  margin-bottom: .3rem;
}

.logo-powered{
  text-align: center;
  bottom:0;
  position: absolute;
  left: 50%;
  margin-left: -55px
}
.header {
    padding:0;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s;
  }
  
.trigger:hover {
    color: #1890ff;
}
  
.logo {
    display: block;
    width: 230px;
    height: 30px;
    margin: 15px auto;
    /* background-color: #383a4c; */
}

.logo-collapsed{
    display: block;
    width: 60px;
    height: 30px;
    margin: 15px auto;
    /* background-color: #383a4c; */
}


.ant-collapse-header{
    padding:12px !important;
}
.ant-collapse-header span:last-child {
    float:right;
}
.ant-collapse-header span:first-child {
    float:none;
}

/* sidebar */
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: rgba(0,0,0,0.3);
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.3);
}

.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active{
  color:#fff;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header{
    /* overflow: hidden!important; */
}

/* centered sidebar */
.ant-layout-sider-children {
  display: flex;
}
.ant-menu-dark.ant-menu-vertical{
  margin-top: auto;
  margin-bottom: auto;
}
.ant-menu-dark.ant-menu-vertical .ant-menu-item{
  margin-top: 20px;
  margin-bottom: 20px;
}
.ant-menu-vertical .ant-menu-submenu{
  margin-top: 20px;
  margin-bottom: 20px;
}

.ant-input-disabled,
.ant-input-number-input:disabled, 
.ant-select-disabled .ant-select-selection{
    background-color: #fafafa !important;
    color: rgba(0, 0, 0, 0.65);
}

.text-error {
   color: #f5222d;
}
.header {
    padding:0;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
}
  
.logo {
    display: block;
    width: 230px;
    height: 30px;
    margin: 15px auto;
}

.footer{
    background-color: #fefefe;
    border-top: 1px solid rgba(0, 0, 0, 0.08)
}
.footer-2{
    background-color: #f9f9f9;
    border-top: 1px solid rgba(0, 0, 0, 0.08)
}

.footer-widget .widget-header{
    color: rgba(0, 0, 0, 0.2);
    font-size: 14pt;
    font-weight: 600;
}
.footer-widget .widget-content p, 
.footer-widget .widget-content a{
    margin-bottom:10px;
    font-size: 10pt;
    color:rgba(0, 0, 0, 0.8);
}

.cr{
    color:rgba(0, 0, 0, 0.3);
    font-size: 9pt;
}

.nav-btn{
    display: inline;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}
.nav-btn:hover, .nav-btn:active, .nav-btn:focus{
    background-color: rgba(0, 0, 0, 0.1);
}

.menu-popup-wrapper{
    position: fixed;
}
.menu-popup{
    background: #fefefe;
    width: 300px;
    /* height: 300px; */
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.menu-popup::before{
    content: ' ';
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fefefe;
}
.menu-popup .menu-header{
    padding:10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.menu-popup .menu-content{
    padding:10px;
}
.menu-popup .menu-footer{
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding:10px;
    text-align: center
}

.card-product .ant-card-body{
    padding:10px
}
.card-product .ant-card-body .ant-card-meta-title{
    font-size: 11pt;
    font-weight: normal;
}
.pageTitle{
    font-size: 20px;
}

p.col-overflow{
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
    text-align: left !important;
}

/* .ant-form label, .ant-collapse, .ant-select-dropdown, .ant-table, .ant-pagination, .ant-select, .ant-breadcrumb, 
.ant-table-placeholder, .ant-dropdown-menu-item, .ant-checkbox-wrapper, .ant-radio-wrapper, .ant-form-item, 
.ant-input, .ant-card {
  font-size: 10px !important;
} */

/* body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", 
  "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", 
  "Segoe UI Symbol" !important;
} */
.advanced-search-form {
}
  
.advanced-search-form .ant-form-item {
    margin-bottom: 5px;
}

.advanced-search-form .ant-form-item-control-wrapper {
}

.advanced-search-form .ant-form-item .ant-form-item-label{
    line-height: 16pt
}

.ant-select-arrow{
  display:none
}

.container{
  position: relative;
  top: 0;
  right: 0;
  display: inline-block;
}

.button {
  border: none;
  padding: 0 !important;
  margin-left: -25px;
  marginTop: 5px;
  backgroundColor: transparent;
  box-shadow: none;
  border-radius:0;
  height:auto;
}

.ant-select-selection__clear{
  right:30px;
}

.select-desc{
  line-height: normal;
  margin-bottom: 0;
}

/* table */
th.col-number, 
td.col-number {
  text-align: right !important;
}

td.col-overflow{
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th.col-center, 
td.col-center {
  text-align: center !important;
}
