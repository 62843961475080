.header {
    padding:0;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
}
  
.logo {
    display: block;
    width: 230px;
    height: 30px;
    margin: 15px auto;
}

.footer{
    background-color: #fefefe;
    border-top: 1px solid rgba(0, 0, 0, 0.08)
}
.footer-2{
    background-color: #f9f9f9;
    border-top: 1px solid rgba(0, 0, 0, 0.08)
}

.footer-widget .widget-header{
    color: rgba(0, 0, 0, 0.2);
    font-size: 14pt;
    font-weight: 600;
}
.footer-widget .widget-content p, 
.footer-widget .widget-content a{
    margin-bottom:10px;
    font-size: 10pt;
    color:rgba(0, 0, 0, 0.8);
}

.cr{
    color:rgba(0, 0, 0, 0.3);
    font-size: 9pt;
}

.nav-btn{
    display: inline;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}
.nav-btn:hover, .nav-btn:active, .nav-btn:focus{
    background-color: rgba(0, 0, 0, 0.1);
}

.menu-popup-wrapper{
    position: fixed;
}
.menu-popup{
    background: #fefefe;
    width: 300px;
    /* height: 300px; */
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.menu-popup::before{
    content: ' ';
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fefefe;
}
.menu-popup .menu-header{
    padding:10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.menu-popup .menu-content{
    padding:10px;
}
.menu-popup .menu-footer{
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding:10px;
    text-align: center
}

.card-product .ant-card-body{
    padding:10px
}
.card-product .ant-card-body .ant-card-meta-title{
    font-size: 11pt;
    font-weight: normal;
}