.header {
    padding:0;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
  }
  
.trigger:hover {
    color: #1890ff;
}
  
.logo {
    display: block;
    width: 230px;
    height: 30px;
    margin: 15px auto;
    /* background-color: #383a4c; */
}

.logo-collapsed{
    display: block;
    width: 60px;
    height: 30px;
    margin: 15px auto;
    /* background-color: #383a4c; */
}


.ant-collapse-header{
    padding:12px !important;
}
.ant-collapse-header span:last-child {
    float:right;
}
.ant-collapse-header span:first-child {
    float:none;
}

/* sidebar */
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: rgba(0,0,0,0.3);
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.3);
}

.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active{
  color:#fff;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header{
    /* overflow: hidden!important; */
}

/* centered sidebar */
.ant-layout-sider-children {
  display: flex;
}
.ant-menu-dark.ant-menu-vertical{
  margin-top: auto;
  margin-bottom: auto;
}
.ant-menu-dark.ant-menu-vertical .ant-menu-item{
  margin-top: 20px;
  margin-bottom: 20px;
}
.ant-menu-vertical .ant-menu-submenu{
  margin-top: 20px;
  margin-bottom: 20px;
}

.ant-input-disabled,
.ant-input-number-input:disabled, 
.ant-select-disabled .ant-select-selection{
    background-color: #fafafa !important;
    color: rgba(0, 0, 0, 0.65);
}

.text-error {
   color: #f5222d;
}