.pageTitle{
    font-size: 20px;
}

p.col-overflow{
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
    text-align: left !important;
}

/* .ant-form label, .ant-collapse, .ant-select-dropdown, .ant-table, .ant-pagination, .ant-select, .ant-breadcrumb, 
.ant-table-placeholder, .ant-dropdown-menu-item, .ant-checkbox-wrapper, .ant-radio-wrapper, .ant-form-item, 
.ant-input, .ant-card {
  font-size: 10px !important;
} */

/* body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", 
  "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", 
  "Segoe UI Symbol" !important;
} */