.advanced-search-form {
}
  
.advanced-search-form .ant-form-item {
    margin-bottom: 5px;
}

.advanced-search-form .ant-form-item-control-wrapper {
}

.advanced-search-form .ant-form-item .ant-form-item-label{
    line-height: 16pt
}

.ant-select-arrow{
  display:none
}

.container{
  position: relative;
  top: 0;
  right: 0;
  display: inline-block;
}

.button {
  border: none;
  padding: 0 !important;
  margin-left: -25px;
  marginTop: 5px;
  backgroundColor: transparent;
  box-shadow: none;
  border-radius:0;
  height:auto;
}

.ant-select-selection__clear{
  right:30px;
}

.select-desc{
  line-height: normal;
  margin-bottom: 0;
}

/* table */
th.col-number, 
td.col-number {
  text-align: right !important;
}

td.col-overflow{
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th.col-center, 
td.col-center {
  text-align: center !important;
}